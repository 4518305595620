<template>
  <v-footer v-if="isLoggedIn" fixed padless class="pa-3">
    <v-row>
      <v-col class="text-center" v-for="(link, i) in links" :key="i">
        <v-btn class="mx-5" icon @click="$router.push(link.path)"
          ><v-icon class="mx-3" size="24px" >{{ link.icon }}</v-icon
          >{{ link.label }}</v-btn
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    links: [
      { path: "/", icon: "mdi-home", label: "Home" },
      { path: "/profile", icon: "mdi-account", label: "Profile" },
    ],
  }),

  components: {},

  created() {},

  beforeDestroy() {},

  computed: {
    ...mapGetters(["isLoggedIn"]),
  },

  methods: {},
};
</script>

<style lang="scss"></style>
