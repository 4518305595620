<template>
  <v-app dark>
    <Header>
      <slot name="header"></slot>
    </Header>

    <v-main class="main">
      <v-progress-linear :active="isLoading" indeterminate absolute top color="accent"></v-progress-linear>
      <slot />
    </v-main>

    <Footer />
    <SnackBar />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/_layout/header.vue";
import Footer from "@/components/_layout/footer.vue";
import SnackBar from "@/components/_layout/snackbar.vue";

export default {
  data: () => ({}),

  components: {
    Header,
    Footer,
    SnackBar,
  },

  created() {},

  beforeDestroy() {},

  computed: {
    ...mapGetters(["isLoading"]),
  },

  methods: {},
};
</script>

<style lang="scss">
#app {
  background-color: #20242a;
  background: linear-gradient(5deg, #20242a 92%, #cecece 100%);
}
</style>
