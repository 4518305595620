<template>
  <div class="header">
    <div v-if="isLoggedIn" class="userInfo">
      <v-menu top close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on"><v-icon color="primary" class="pr-2">mdi-account</v-icon>{{ fullName }} </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn text block @click="$router.push('/logout')"><v-icon color="primary" class="pr-5">mdi-logout</v-icon>Logout </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({}),

  components: {},

  created() {},

  beforeDestroy() {},

  computed: {
    ...mapGetters(["isLoggedIn", "fullName"]),
  },

  methods: {},
};
</script>

<style lang="scss">
.header {
  padding: 1rem;
  .userInfo {
    float: right;
  }
}
</style>
